// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-access-leves-tsx": () => import("./../../../src/pages/access-leves.tsx" /* webpackChunkName: "component---src-pages-access-leves-tsx" */),
  "component---src-pages-admin-emails-index-tsx": () => import("./../../../src/pages/admin/emails/index.tsx" /* webpackChunkName: "component---src-pages-admin-emails-index-tsx" */),
  "component---src-pages-admin-messages-message-dashboard-index-tsx": () => import("./../../../src/pages/admin/messages/message-dashboard/index.tsx" /* webpackChunkName: "component---src-pages-admin-messages-message-dashboard-index-tsx" */),
  "component---src-pages-admin-messages-message-form-index-tsx": () => import("./../../../src/pages/admin/messages/message-form/index.tsx" /* webpackChunkName: "component---src-pages-admin-messages-message-form-index-tsx" */),
  "component---src-pages-admin-users-tsx": () => import("./../../../src/pages/admin-users.tsx" /* webpackChunkName: "component---src-pages-admin-users-tsx" */),
  "component---src-pages-blocked-tsx": () => import("./../../../src/pages/blocked.tsx" /* webpackChunkName: "component---src-pages-blocked-tsx" */),
  "component---src-pages-certification-tsx": () => import("./../../../src/pages/certification.tsx" /* webpackChunkName: "component---src-pages-certification-tsx" */),
  "component---src-pages-challenges-tsx": () => import("./../../../src/pages/challenges.tsx" /* webpackChunkName: "component---src-pages-challenges-tsx" */),
  "component---src-pages-data-complement-tsx": () => import("./../../../src/pages/data-complement.tsx" /* webpackChunkName: "component---src-pages-data-complement-tsx" */),
  "component---src-pages-email-sign-up-tsx": () => import("./../../../src/pages/email-sign-up.tsx" /* webpackChunkName: "component---src-pages-email-sign-up-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-quiz-index-tsx": () => import("./../../../src/pages/quiz/index.tsx" /* webpackChunkName: "component---src-pages-quiz-index-tsx" */),
  "component---src-pages-quiz-quiz-password-index-tsx": () => import("./../../../src/pages/quiz/quiz-password/index.tsx" /* webpackChunkName: "component---src-pages-quiz-quiz-password-index-tsx" */),
  "component---src-pages-quiz-result-index-tsx": () => import("./../../../src/pages/quiz/result/index.tsx" /* webpackChunkName: "component---src-pages-quiz-result-index-tsx" */),
  "component---src-pages-quiz-state-and-cities-ts": () => import("./../../../src/pages/quiz/state-and-cities.ts" /* webpackChunkName: "component---src-pages-quiz-state-and-cities-ts" */),
  "component---src-pages-quiz-steps-index-tsx": () => import("./../../../src/pages/quiz/steps/index.tsx" /* webpackChunkName: "component---src-pages-quiz-steps-index-tsx" */),
  "component---src-pages-quiz-steps-questions-ts": () => import("./../../../src/pages/quiz/steps/questions.ts" /* webpackChunkName: "component---src-pages-quiz-steps-questions-ts" */),
  "component---src-pages-quiz-types-ts": () => import("./../../../src/pages/quiz/types.ts" /* webpackChunkName: "component---src-pages-quiz-types-ts" */),
  "component---src-pages-recovery-password-tsx": () => import("./../../../src/pages/recovery-password.tsx" /* webpackChunkName: "component---src-pages-recovery-password-tsx" */),
  "component---src-pages-sent-email-to-recovery-tsx": () => import("./../../../src/pages/sent-email-to-recovery.tsx" /* webpackChunkName: "component---src-pages-sent-email-to-recovery-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-unlock-account-google-tsx": () => import("./../../../src/pages/unlock-account-google.tsx" /* webpackChunkName: "component---src-pages-unlock-account-google-tsx" */),
  "component---src-pages-unlock-account-tsx": () => import("./../../../src/pages/unlock-account.tsx" /* webpackChunkName: "component---src-pages-unlock-account-tsx" */),
  "component---src-pages-unsubscribed-tsx": () => import("./../../../src/pages/unsubscribed.tsx" /* webpackChunkName: "component---src-pages-unsubscribed-tsx" */),
  "component---src-pages-update-email-tsx": () => import("./../../../src/pages/update-email.tsx" /* webpackChunkName: "component---src-pages-update-email-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */),
  "component---src-pages-verification-email-tsx": () => import("./../../../src/pages/verification-email.tsx" /* webpackChunkName: "component---src-pages-verification-email-tsx" */),
  "component---src-templates-challenges-classic-show-tsx": () => import("./../../../src/templates/Challenges/classic/show.tsx" /* webpackChunkName: "component---src-templates-challenges-classic-show-tsx" */),
  "component---src-templates-challenges-projects-frontend-show-tsx": () => import("./../../../src/templates/Challenges/projects/frontend/show.tsx" /* webpackChunkName: "component---src-templates-challenges-projects-frontend-show-tsx" */),
  "component---src-templates-challenges-quiz-show-tsx": () => import("./../../../src/templates/Challenges/quiz/show.tsx" /* webpackChunkName: "component---src-templates-challenges-quiz-show-tsx" */),
  "component---src-templates-challenges-text-only-show-tsx": () => import("./../../../src/templates/Challenges/textOnly/show.tsx" /* webpackChunkName: "component---src-templates-challenges-text-only-show-tsx" */),
  "component---src-templates-challenges-video-only-show-tsx": () => import("./../../../src/templates/Challenges/videoOnly/show.tsx" /* webpackChunkName: "component---src-templates-challenges-video-only-show-tsx" */),
  "component---src-templates-challenges-video-show-tsx": () => import("./../../../src/templates/Challenges/video/show.tsx" /* webpackChunkName: "component---src-templates-challenges-video-show-tsx" */),
  "component---src-templates-introduction-intro-tsx": () => import("./../../../src/templates/Introduction/intro.tsx" /* webpackChunkName: "component---src-templates-introduction-intro-tsx" */),
  "component---src-templates-introduction-super-block-intro-tsx": () => import("./../../../src/templates/Introduction/super-block-intro.tsx" /* webpackChunkName: "component---src-templates-introduction-super-block-intro-tsx" */)
}

