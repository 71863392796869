/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { CSSProperties, forwardRef } from 'react';
import {
  FormGroup,
  FormControl,
  ControlLabel
} from '@devstart/react-bootstrap';
import InputMask from 'react-input-mask';

import './input.css';

type StyleTypeProps = 'type-1' | 'type-2';

type InputProps = {
  label?: string;
  icon?: React.ReactNode;
  insideIcon?: React.ReactNode;
  hasError?: boolean;
  style?: CSSProperties;
  error?: string | null;
  disabled?: boolean;
  hasMask?: boolean;
  maskValue?: string;
  messageError?: string;
  inputStyleType?: StyleTypeProps;
} & React.HTMLAttributes<HTMLInputElement> & { [key: string]: unknown };

const getInputStyleType = (styleType: StyleTypeProps) => {
  switch (styleType) {
    case 'type-1':
      return '';
    case 'type-2':
      return 'style-type-2';
    default:
      return '';
  }
};

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    label,
    icon,
    hasError,
    style,
    insideIcon,
    error,
    hasMask,
    maskValue,
    messageError,
    inputStyleType = 'type-1',
    ...rest
  },
  ref
): JSX.Element {
  const inputError = messageError || error || (hasError ? messageError : null);

  const borderStyle: CSSProperties = icon
    ? { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }
    : { borderRadius: '8px' };

  if (
    (rest.name === 'effectiveDate' || rest.name === 'expirationDate') &&
    rest?.value === ''
  ) {
    borderStyle.borderColor = '#ADADAD';
  }

  const hasErrorStyle = inputError
    ? { color: '#DC3545', fontSize: '12px', fontWeight: '400' }
    : {};

  if (hasMask) {
    return (
      <div
        className={`input-container form-group ${getInputStyleType(
          inputStyleType
        )}`}
      >
        <InputMask
          className='form-control form-control-input'
          mask={maskValue ?? ''}
          inputRef={ref}
          style={{ ...borderStyle }}
          {...rest}
        />
        {inputError && <p style={{ ...hasErrorStyle }}>{inputError}</p>}
      </div>
    );
  }

  return (
    <FormGroup
      style={style}
      className={`input-container ${getInputStyleType(inputStyleType)}`}
    >
      {label && <ControlLabel>{label}</ControlLabel>}

      <div className={`input-field ${insideIcon ? 'has-feedback' : ''}`}>
        {icon && (
          <span className='input-group-addon icon-container' id='basic-addon1'>
            {icon}
          </span>
        )}
        <FormControl style={{ ...borderStyle }} inputRef={ref} {...rest} />
        {insideIcon && (
          <div className='form-control-feedback'>{insideIcon}</div>
        )}
      </div>

      {inputError && <p style={{ ...hasErrorStyle }}>{inputError}</p>}
    </FormGroup>
  );
});

Input.displayName = 'Input';

export default Input;
