import { QuizUser } from '../../pages/quiz/types';
import { StandardResponse, post, put, ResponseWithData } from '.';

export async function registerQuizUser(
  payload: QuizUser
): Promise<StandardResponse<QuizUser>> {
  const responseWithData: Promise<
    ResponseWithData<StandardResponse<QuizUser>>
  > = post('/api/quiz-user/register', payload);

  return responseWithData.then(res => res.data);
}

export async function updateQuizUserResult({
  userId,
  result
}: {
  userId: string;
  result: number; // 1 or 2
}): Promise<StandardResponse<QuizUser>> {
  const responseWithData: Promise<
    ResponseWithData<StandardResponse<QuizUser>>
  > = put(`/api/quiz-user/update-result/${userId}`, { userResult: result });

  return responseWithData.then(res => res.data);
}

export async function toggleReceiveEmails({
  userId,
  receiveEmails
}: {
  userId: string;
  receiveEmails: boolean;
}): Promise<StandardResponse<QuizUser>> {
  const responseWithData: Promise<
    ResponseWithData<StandardResponse<QuizUser>>
  > = put(`/api/quiz-user/toggle-receive-emails/${userId}`, {
    receiveEmails
  });

  return responseWithData.then(res => res.data);
}

export async function createNewUserFromQuiz({
  userId,
  password
}: {
  userId: string;
  password: string;
}): Promise<StandardResponse<QuizUser>> {
  const responseWithData: Promise<
    ResponseWithData<StandardResponse<QuizUser>>
  > = post(`/api/quiz-user/new-user/${userId}`, { password });

  return responseWithData.then(res => res.data);
}

export async function findQuizUser({
  email,
  cpf
}: {
  email: string;
  cpf: string;
}): Promise<StandardResponse<QuizUser>> {
  const responseWithData: Promise<
    ResponseWithData<StandardResponse<QuizUser>>
  > = post('/api/quiz-user/find-quiz-user', {
    email,
    cpf
  });

  return responseWithData.then(res => res.data);
}
