/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

export function formatPhoneValue(value: string): boolean {
  const phoneWithoutMask: string = value
    .replaceAll(' ', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('-', '')
    .replaceAll('_', '');
  return phoneWithoutMask.length === 11 ? true : false;
}

export function formatCpfValue(value: string): boolean {
  const cpfWithoutMask: string = value
    .replaceAll('.', '')
    .replaceAll('-', '')
    .replaceAll('_', '');
  return cpfWithoutMask.length === 11 ? true : false;
}

export function isCPFValid(cpf: string): boolean {
  const cpfRaw = cpf.replace(/[^\d]/g, '');

  if (/^(\d)\1+$/.test(cpfRaw)) {
    return false;
  }

  const calcDigit = (cpf: string, weight: number) => {
    const sum = cpf
      .slice(0, weight)
      .split('')
      .map(Number)
      .reduce((acc, digit, index) => acc + digit * (weight - index), 0);
    const mod = sum % 11;
    return mod < 2 ? 0 : 11 - mod;
  };

  const firstDigit = calcDigit(cpfRaw.slice(0, 9), 10);
  const secondDigit = calcDigit(cpfRaw.slice(0, 10), 11);

  if (
    parseInt(cpfRaw.charAt(9)) !== firstDigit ||
    parseInt(cpfRaw.charAt(10)) !== secondDigit
  ) {
    return false;
  } else {
    return true;
  }
}

export function isPasswordValid(password: string): boolean {
  if (password.length < 8) {
    return false;
  } else {
    return true;
  }
}

export const isValidPhoneNumber = (value: string) => {
  const valueOnlyNumbers = value.replace(/\D/g, '');
  if (valueOnlyNumbers.length < 10) {
    return false;
  }
  return true;
};

export const isEmail = (value: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};
