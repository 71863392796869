import { call, delay, put, takeEvery } from 'redux-saga/effects';
import isEmail from 'validator/lib/isEmail';
import { hardGoTo } from '@redux/actions';
import { apiLocation } from '../../../../config/env.json';
import { createFlashMessage } from '../../components/Flash/redux';
import { postCheckPassword, putUserUpdateEmail } from '../../utils/ajax';
import reallyWeirdErrorMessage from '../../utils/really-weird-error-message';
import { updateMyEmailComplete, updateMyEmailError } from './actions';

function* updateMyEmailSaga({ payload: { email = '', password = '' } }) {
  if (!email || !isEmail(email)) {
    yield put(createFlashMessage(reallyWeirdErrorMessage));
    return;
  }

  if (!password) {
    yield put(createFlashMessage('A senha é obrigatória.'));
    return;
  }

  try {
    const { data } = yield call(postCheckPassword, password);
    const { success } = data;
    if (!success) {
      yield put(createFlashMessage(data));
      return;
    }
    yield put(createFlashMessage(data));
  } catch (e) {
    yield put(createFlashMessage(e));
  }

  try {
    const { data } = yield call(putUserUpdateEmail, email);
    yield put(
      updateMyEmailComplete({
        ...data,
        payload: { email, isEmailVerified: false }
      })
    );
    yield put(createFlashMessage(data));

    const { success } = data;

    if (success) {
      yield delay(2000);
      yield put(hardGoTo(`${apiLocation}/signout`));
    }
  } catch (e) {
    yield put(updateMyEmailError(e));
  }
}

export function createUpdateMyEmailSaga(types) {
  return [takeEvery(types.updateMyEmail, updateMyEmailSaga)];
}
