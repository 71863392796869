import { call, put, takeLatest } from 'redux-saga/effects';
import { createFlashMessage } from '@components/Flash/redux';
import { navigate } from 'gatsby-link';
import {
  registerQuizUser as createRegisterQuizUser,
  updateQuizUserResult as createUpdateQuizUserResult,
  toggleReceiveEmails as createToggleReceiveEmails,
  createNewUserFromQuiz as createCreateNewUserFromQuiz
} from '../utils/api/quiz';
import { actionTypes } from './action-types';
import { signInLocal } from './actions';

function* registerQuizUser({ payload }) {
  try {
    const { result, success, message } = yield call(
      createRegisterQuizUser,
      payload
    );

    if (success) {
      yield put({
        type: actionTypes.registerQuizUserComplete,
        payload: result
      });

      if (result.id) {
        if (result.registrationQuiz) {
          void navigate('/quiz/result');
        } else {
          void navigate('/quiz/steps');
        }
      }
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );

      yield put({
        type: actionTypes.registerQuizUserComplete,
        payload: null
      });
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.'
      })
    );

    yield put({
      type: actionTypes.registerQuizUserComplete,
      payload: null
    });
  }
}

function* updateQuizUserResult({ payload }) {
  try {
    const { result, success, message } = yield call(
      createUpdateQuizUserResult,
      payload
    );

    if (success) {
      yield put({
        type: actionTypes.updateQuizUserResultComplete,
        payload: result
      });

      yield put(
        createFlashMessage({
          type: 'success',
          message,
          variables: { dismissible: true }
        })
      );

      if (result.id) {
        void navigate('/quiz/result');
      }
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

function* toggleReceiveEmails({ payload }) {
  try {
    const { result, success, message } = yield call(
      createToggleReceiveEmails,
      payload
    );

    if (success) {
      yield put({
        type: actionTypes.toggleReceiveEmailsComplete,
        payload: result
      });

      yield put(
        createFlashMessage({
          type: 'success',
          message,
          variables: { dismissible: true }
        })
      );

      navigate('/');
    } else {
      yield put(createFlashMessage({ type: 'danger', message, variables: {} }));
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

function* createNewUserFromQuiz({ payload }) {
  try {
    const { success, message, result } = yield call(
      createCreateNewUserFromQuiz,
      payload
    );

    if (success) {
      yield put({
        type: actionTypes.createNewUserFromQuizComplete,
        payload: result
      });

      yield put(
        createFlashMessage({
          type: 'success',
          message,
          variables: { dismissible: true }
        })
      );

      yield put(
        signInLocal({
          email: result.email,
          password: payload.password
        })
      );
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (error) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: 'Indisponível, tente novamente em alguns minutos.',
        variables: { dismissible: true }
      })
    );
  }
}

export function createQuizUserSaga(types) {
  return [
    takeLatest(types.registerQuizUser, registerQuizUser),
    takeLatest(types.updateQuizUserResult, updateQuizUserResult),
    takeLatest(types.toggleReceiveEmails, toggleReceiveEmails),
    takeLatest(types.createNewUserFromQuiz, createNewUserFromQuiz)
  ];
}
